if (document.querySelectorAll(".swiper-intro").length > 0) {
    const swiper = new Swiper('.swiper-intro', {
        direction: 'horizontal',
        effect: "fade",
        lazy: true,
        autoplay: {
            delay: 6000,
            speed: 1000,
            disableOnInteraction: false,
        },
        loop: true,
        preventInteractionOnTransition: true,
        pagination: {
            el: ".swiper-intro .swiper-pagination",
            type: "fraction",
        },
        navigation: {
            nextEl: '.swiper-intro .swiper-button-next',
            prevEl: '.swiper-intro .swiper-button-prev',
        },
        slideActiveClass: 'swiper-slide-active',
    });

    swiper.on('slideResetTransitionEnd', function(swiper, transition) {
        document.querySelector(".swiper-intro .swiper-slide-active").classList.remove("swiper-slide-active");

    });

}
if (document.querySelectorAll(".recommended").length > 0) {
    const swiper4 = new Swiper('.swiper-recommended', {

        effect: "fade",      
        lazy: true,

        autoplay: {
            delay: 6000,
            speed:3000,
        },
        loop: true,
        pagination: {
            el: ".swiper-recommended .swiper-pagination",
            type: "fraction",
        },

        navigation: {
            nextEl: '.swiper-recommended .swiper-button-next',
            prevEl: '.swiper-recommended .swiper-button-prev',
        },

        slideActiveClass: 'swiper-slide-active',

    });
    swiper4.on('slideResetTransitionEnd', function(swiper, transition) {
        document.querySelector(".swiper-recommended .swiper-slide-active").classList.remove("swiper-slide-active");

    });
}

if (document.querySelectorAll(".swiper-special").length > 0) {
    const swiper2 = new Swiper('.swiper-special-thumbs', {
        spaceBetween: 10,
        slidesPerView: 3,
        freeMode: true,
        watchSlidesProgress: true,
        autoplay: {
            delay: 6000,
            speed:3000,
        },


        slideActiveClass: 'swiper-slide-active',
    });
    swiper2.on('slideResetTransitionEnd', function(swiper, transition) {
        document.querySelector(".swiper-special-thumbs .swiper-slide-active").classList.remove("swiper-slide-active");

    });
    const swiper3 = new Swiper('.swiper-special', {
        direction: 'horizontal',
        effect: "fade",
        autoplay: {
            delay: 6000,
            speed:3000,
        },

        thumbs: {
            swiper: swiper2,
        },
    });
}